import { INGPageProps, RuntimeContext } from "../library/NGFieldExtensions";
import { ThemeProvider } from "@mui/material/styles";
import { Dialog, Page, Snackbar } from "../../resolvers-types";
import { findDialogs } from "../library/utils";
import NGDialog from "../components/NGDialog/NGDialog";
import { setupHandlers, setupInitialState, setupLocalState } from "../library/dataService";
import { isNil } from "lodash-es";
import { signal, useSignal, useSignalEffect } from "@preact/signals-react";
import NGLayout from "../layouts/NGLayout";
import { GlobalStyles } from "@mui/material";
import { log } from "../library/logger.ts";
import { renderModalPopups, setupModalPopup } from "../components/ComponentUtils.tsx";
import NGSnackbar from "../components/NGSnackbar/NGSnackbar.tsx";

export default function NGPage({ config, layout, context }: INGPageProps) {
  const tag = "NGPage";
  //generate a function to return all the Applications (recursively) and containers (top level only) for a given page
  const dialog: Dialog = { Id: "NGMessage", __typename: "Dialog" };
  const snackbar: Snackbar = { Id: "NGSnackbarMessage", __typename: "Snackbar" };

  context.InDesignMode = config.InDesignMode as boolean;

  // const currentContext = updateItemContext(context, config);

  const local = setupLocalState(
    config,
    {
      InDesignMode: useSignal(config.InDesignMode || false),
      Visible: useSignal(config.Visible ?? true),
      Theme: useSignal(config.Theme ?? "default"),
      Styles: useSignal(config.Styles ?? []),
    },
    context
  );

  setupInitialState(config, context);

  const handlers = setupHandlers(config, context);

  useSignalEffect(() => {
    if (handlers["onLoad"]) {
      handlers["onLoad"](config, config);
    }

    //TODO: Make this properly part of the theme
    if (!isNil(config.Style) && typeof config.Style.backgroundColor == "string") {
      document.body.style.backgroundColor = config.Style.backgroundColor;
    }
  });

  // const currentTheme = themes[local.Theme.value];

  // log.debug(tag, "currentTheme", currentTheme);
  // log.debug(tag, "styles", local.Styles.value, currentTheme.styles);

  return (
    <>
        <GlobalStyles styles={{ ...local.Styles.value }} />
        <NGDialog key="messageDialog" config={dialog} context={context} />
        <NGSnackbar key="snackbar" config={snackbar} context={context} />
        {renderModalPopups(config, context)}
        {local.Visible.value && layout && (
          <NGLayout layout={layout} layoutItem={config} inDesignMode={local.InDesignMode.value} context={context} />
        )}
    </>
  );
}
