import { SyntheticEvent, useCallback, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { ReadonlySignal, useComputed, useSignal } from "@preact/signals-react";
import { debounce, isArray, isEqual, isNil, isUndefined, max, min, toNumber } from "lodash-es";
import OdataProvider from "ag-grid-odata";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import {
  AdvancedFilterModule,
  CellStyle,
  ClipboardModule,
  ClientSideRowModelModule,
  ColumnMovedEvent,
  ColumnPinnedEvent,
  ColumnPivotChangedEvent,
  ColumnResizedEvent,
  ColumnsToolPanelModule,
  ColumnRowGroupChangedEvent,
  ColumnValueChangedEvent,
  ColumnVisibleEvent,
  CsvExportModule,
  CsvExportParams,
  DomLayoutType,
  ExcelExportModule,
  ExcelExportParams,
  ExpandOrCollapseAllEvent,
  FiltersToolPanelModule,
  GridOptions,
  IAggFuncParams,
  IDateFilterParams,
  LicenseManager,
  MasterDetailModule,
  MenuModule,
  ModuleRegistry,
  MultiFilterModule,
  RangeSelectionModule,
  RichSelectModule,
  RowClickedEvent,
  RowGroupingModule,
  RowValueChangedEvent,
  SelectionChangedEvent,
  SetFilterModule,
  SideBarModule,
  SizeColumnsToFitGridStrategy,
  SparklinesModule,
  StatusBarModule,
  GridReadyEvent,
  ServerSideRowModelModule,
  RowModelType,
} from "ag-grid-charts-enterprise";
import { Button, IActionTrigger, LayoutItem, List, ListColumn, Maybe, SimpleContainer } from "../../../resolvers-types";
import NGButton from "../NGButton/NGButton";
import NGLayoutItem from "../../generators/NGLayoutItem";
import {
  IButtonMethods,
  INGListProps,
  RowGroupPanelShowOptions,
  RuntimeContext,
} from "../../library/NGFieldExtensions";
import {
  GetFormatFromSite,
  getScope,
  getState,
  mapFields,
  setupHandlers,
  setupLocalState,
  updateItemContext,
} from "../../library/dataService";
import { matchTypename } from "../../library/metadataUtils";
import { generateUID, getTestId, removeTypeName, traverse } from "../../library/utils";
import { GridToolbarExport } from "./Export";
import { GridToolbarQuickFilter } from "./QuickFilter";
import CellRendererList from "./NGCellRendererList";
import {
  addBlankRows,
  addColumns,
  addRowPerPageToSelector,
  checkboxSelection,
  CustomColDef,
  defaultExportParams,
  defaultRowHeight,
  defaultRowsPerPage,
  defaultGroupColumn,
  excelStyles,
  fromColDefsToConfig,
  getBindings,
  getDataTypeFilter,
  getFormatDataType,
  getRowId,
  getRows,
  IAnyObject,
  removePageElements,
} from "./NGListUtils";
import { callOdataQuery, ODataQueryOptions } from "../../library/oDataUtils";

import "ag-grid-charts-enterprise/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-charts-enterprise/styles/ag-theme-quartz.css"; // Mandatory CSS required by the Data Grid
import "./NGList.css";
import QueryRequest from "./oDataQueryBuilder";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-064288}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{JBI_Holdings}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{JBI}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{JBI}_need_to_be_licensed___{JBI}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{29_July_2025}____[v3]_[0102]_MTc1Mzc0MzYwMDAwMA==bd659fcc281dec57d192f14cfd09e0c3"
);

ModuleRegistry.registerModules([
  AdvancedFilterModule,
  ClientSideRowModelModule,
  ServerSideRowModelModule,
  CsvExportModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  MasterDetailModule,
  MenuModule,
  MultiFilterModule,
  RangeSelectionModule,
  RichSelectModule,
  RowGroupingModule,
  SetFilterModule,
  SideBarModule,
  StatusBarModule,
  SparklinesModule,
]);

type IPinned = "left" | "right";
type IOnColumnUpdate =
  | ColumnRowGroupChangedEvent
  | ColumnPivotChangedEvent
  | ColumnMovedEvent
  | ColumnPinnedEvent
  | ColumnVisibleEvent
  | ColumnValueChangedEvent;

const tag = "NGList";

export default function NGList({ config, context }: INGListProps) {
  const GridRef = useRef<AgGridReact<IAnyObject>>(null);
  const ContainerRef = useRef<HTMLDivElement>(null);
  const GridContainerRef = useRef<HTMLDivElement>(null);
  const AdvanceFilterRef = useRef<HTMLDivElement>(null);
  const QuickFilterRef = useRef<HTMLInputElement>(null);
  const testId = getTestId(config);
  const ExportRef = useRef<HTMLButtonElement>(null);

  const currentContext = updateItemContext(context, config, { GridRef, QuickFilterRef });

  const local = setupLocalState(
    config,
    {
      Columns: useSignal([]),
      ListColumns: useSignal(config.ListColumns ?? []),
      Rows: useSignal(config.Rows ?? []),
      Loading: useSignal(config.Loading ?? false),
      QuickFilterText: useSignal(config.QuickFilterText ?? undefined),
      Service: useSignal(config.Service ?? {}),
      RowsPerPage: useSignal(config.RowsPerPage ?? defaultRowsPerPage),
    },
    currentContext
  );
  const location = useLocation();

  function getColumns(
    config: List,
    cols: Maybe<ListColumn>[] | undefined | null,
    listCols: Maybe<ListColumn>[] | undefined | null,
    context: RuntimeContext
  ): CustomColDef[] {
    const MUICols: CustomColDef[] = [];
    const allCols = [...(cols ?? []), ...(isArray(listCols) ? listCols : [])];

    if (isNil(allCols) || allCols.length === 0) return MUICols;

    // const visibleColumnCount = allCols.filter((item) => item?.Visible !== false).length;
    // const defaultFlexWidth = visibleColumnCount > 0 ? 1 / visibleColumnCount : 1;

    allCols.forEach((col, idx) => {
      if (!col) return;

      const format = col.FormatName ? GetFormatFromSite(col.FormatName) : null;
      const newCol: CustomColDef = {
        field: col.Name as string,
        valueFormatter: (p) => getFormatDataType(format, p.value, col.DataType),
        filter: col.DisableFiltering ? false : getDataTypeFilter(col.DataType),
        editable: col.Editable as boolean,
        hide: !isUndefined(col.Visible) ? !col.Visible : false,
        headerName: col.HeaderName as string,
        width: col.Width ? toNumber(col.Width) : undefined,
        suppressHeaderMenuButton: !!col.SuppressHeaderMenu,
        cellStyle: col.Style as CellStyle,
        cellClass: col.Classes ?? "",
        colId: col.Id ?? col.Name ?? generateUID(),
        flex: col.Flex ?? undefined,
        cellDataType: col.DataType ?? undefined,
        rowGroup: col.RowGroup ?? undefined,
        aggFunc: col.AggFunc,
        defaultAggFunc: col.DataType === "number" ? "sum" : "first",
        pivot: col.Pivot ?? undefined,
        pinned: col.Pinned as IPinned,
        listCol: col,
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            if (!cellValue) return 0;

            const [month, day, year] = cellValue.split("/").map(Number);
            const cellDate = new Date(year, month - 1, day);

            if (cellDate < filterLocalDateAtMidnight) return -1;
            if (cellDate > filterLocalDateAtMidnight) return 1;
            return 0;
          },
        } as IDateFilterParams,
      };

      if (col.CellLayout || col.DisplayAsChip) {
        newCol.cellRenderer = (params: CustomCellRendererProps) => {
          const clonedConfig = getBindings(params, context, col.CellLayout);
          if (col.DisplayAsChip) {
            clonedConfig.DisplayAsChip = col.DisplayAsChip;
            clonedConfig.__typename = "Label";
            clonedConfig.ChipClasses = col.ChipClasses;
          }
          return <NGLayoutItem config={clonedConfig} context={context} />;
        };
      }

      if (isArray(col.CellRendererItems) && col.CellRendererItems.length) {
        newCol.cellRenderer = (params: CustomCellRendererProps) => (
          <CellRendererList {...params} context={context} components={col.CellRendererItems} />
        );
      }

      MUICols.push(newCol);
    });

    return MUICols;
  }

  const handlers = setupHandlers(config, currentContext, location);

  function onInput() {
    GridRef.current?.api.setGridOption("quickFilterText", QuickFilterRef.current?.value);
    local.QuickFilterText.value = QuickFilterRef.current?.value;
  }

  function convertLegacyGrid() {
    return {
      __typename: "SimpleContainer",
      Id: generateUID(),
      IgnoreLayout: true,
      Style: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        minHeight: "20px",
        flex: 1,
        justifyContent: "space-between",
      },
      Items: [
        // add items from config.Toolbar.Items
        ...((config as any)?.Toolbar?.Items ?? []),
        ...(config?.Items ?? []),
      ],
    } as SimpleContainer;
  }

  function EditToolbar() {
    const hasTopContainer = config.Items && config.Items[0] && config.Items[0]?.__typename == "SimpleContainer";

    // Toolbar at the top level always has a container
    const container = hasTopContainer ? (config.Items as any)[0] : convertLegacyGrid();

    if (!hasTopContainer) config.Items = [container];

    function addItem(item: any, position: number) {
      if (isNil(container.Items)) container.Items = [];

      if (position === -1) {
        container.Items.push(item);
      } else {
        container.Items.splice(position, 0, item);
      }
    }

    const quickFilterRefs: any[] = [];
    traverse(config.Items, 0, quickFilterRefs, "ListQuickFilter");

    const exportRefs: any[] = [];
    traverse(config.Items, 0, exportRefs, "ListExportButton");

    const hasExportButton = exportRefs.length > 0;
    const hasQuickFilter = quickFilterRefs.length > 0;

    if (!hasExportButton)
      addItem(
        {
          __typename: "ListExportButton",
          EnableCSV: true,
          EnableExcel: true,
          Visible: (config as any).ShowExport !== false && (config as any).Toolbar?.ShowExport !== false,
        },
        0
      );

    if (!hasQuickFilter) {
      const quickFilterAction = config?.Actions?.find((a) => a?.Trigger === "onQuickFilterChange");
      addItem(
        {
          __typename: "ListQuickFilter",
          Actions: [quickFilterAction],
          Adornment: { Position: "Start", Icon: { IconName: "Search" } },
          Visible: (config as any).ShowQuickFilter !== false,
          //onInput: onInput
        },
        -1
      );
    }

    //if (config.ShowQuickFilter) {
    quickFilterRefs.forEach((ref) => {
      ref.onInput = onInput;
    });
    //}

    return (
      <>
        {config.Items?.map((item, key) => (
          <NGLayoutItem key={key} config={item as LayoutItem} context={currentContext} />
        ))}
      </>
    );

    // const toolbarItems = [
    //   // add items from config.Toolbar.Items
    //   ...(config?.Toolbar?.Items ?? []),
    //   ...(config?.Items ?? []),
    // ];

    // return (
    //   <Stack direction="row" margin="1rem" ref={ContainerRef}>
    //     {toolbarItems?.map((item, key) => (
    //       // inject GridRef, QuickFilterRef, onInput to all items in order to have access to them
    //       <NGLayoutItem
    //         key={key}
    //         config={
    //           {
    //             ...item,
    //             GridRef,
    //             QuickFilterRef,
    //             onInput,
    //           } as unknown as LayoutItem
    //         }
    //         context={context}
    //       />
    //     ))}
    //   </Stack>
    // );

    // type ListExportButton {
    //   EnablePrint: Boolean
    //   EnableCSV: Boolean
    //   EnableExcel: Boolean

    //   FileName: String

    //   CSVDelimiter: String
    //   ExcelSheetName: String

    //   PrintHideFooter: Boolean
    //   PrintHideToolbar: Boolean
    // }

    // type ListQuickFilter {
    //   """
    //   Placeholder text shown in the editor when no content is present
    //   """
    //   PlaceholderText: String

    //   Adornment: Adornment
    // }

    // Export button is added if not present in collection
    // {config.Items?.filter((x) => x.__typename != "TabItem").map((item, key) => (
    //   <NGLayoutItem key={key} config={item as LayoutItem} context={context} />
    // ))}
  }

  // function EditToolbar2() {
  //   const addColumnsToSheetButton: Button = {
  //     __typename: "Button",
  //     Id: `${config.Id}_AddColumnsButton`,
  //     Label: "Add 10 Columns",
  //     StartIcon: { IconName: "Add" },
  //     Actions: [
  //       {
  //         Trigger: "onClick",
  //         preHandler: (
  //           handlerName: string,
  //           action: IActionTrigger,
  //           e: SyntheticEvent,
  //           data: object,
  //           formCtx: unknown
  //         ) => {
  //           const newCols = addColumns(config, local.Columns.value.length ?? 0, true, 10);

  //           local.Columns.value = [
  //             ...local.Columns.value,
  //             ...getColumns(config, newCols, local.ListColumns.value, context),
  //           ];
  //           console.log(tag, "onClick", { handlerName, e, action }, data, formCtx);
  //         },
  //       },
  //     ],
  //   };

  //   return (
  //     <Stack direction="row" margin="1rem" ref={ContainerRef}>
  //       {(config.ShowExport || config.Toolbar?.ShowExport) && <GridToolbarExport apiRef={GridRef} ref={ExportRef} />}
  //       {config.SpreadsheetModeOptions?.ShowAddColumnsButton && (
  //         <NGButton config={addColumnsToSheetButton} methods={{} as IButtonMethods} context={context} />
  //       )}
  //       {(config?.Items || config.Toolbar?.Items)?.map((button) => (
  //         <NGLayoutItem key={button?.__typename} config={button as LayoutItem} context={context} />
  //       ))}
  //       {config.ShowQuickFilter && (
  //         <GridToolbarQuickFilter sx={{ marginLeft: "auto" }} onInput={onInput} ref={QuickFilterRef} />
  //       )}
  //     </Stack>
  //   );
  // }

  function BottomButtons() {
    if (!config?.SpreadsheetModeOptions?.ShowAddRowsButton) return null;

    const addRowsToSheetButton: Button = {
      __typename: "Button",
      Id: `${config.Id}_AddRowsButton`,
      Label: "Add 5 Rows",
      StartIcon: { IconName: "Add" },
      Actions: [
        {
          Trigger: "onClick",
          preHandler: (
            handlerName: string,
            action: IActionTrigger,
            e: SyntheticEvent,
            data: object,
            formCtx: unknown
          ) => {
            const newRows = addBlankRows(local.Rows.value, 5);
            local.Rows.value = [...local.Rows.value, ...newRows];
            console.log(tag, "onClick", { handlerName, e, action }, data, formCtx);
          },
        },
      ],
    };

    return (
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <NGButton config={addRowsToSheetButton} methods={{} as IButtonMethods} context={currentContext} />
      </Box>
    );
  }

  function handleOnRowClicked(params: RowClickedEvent<IAnyObject>) {
    handlers["onRowClick"]?.(null, params.data);
  }

  function onSelectionChanged(params: SelectionChangedEvent<IAnyObject>) {
    handlers["onSelectionChanged"]?.(null, params.api.getSelectedRows());
  }

  const onRowValueChanged = useCallback(
    (params: RowValueChangedEvent) => {
      const clonedRows = [...local.Rows.value];
      clonedRows[params.rowIndex ?? 0] = params.data;
      handlers["onRowUpdate"]?.(null, { Rows: clonedRows });
    },
    [handlers, local.Rows.value]
  );

  const handleGridEditorEvent = useCallback(
    (setFn: () => List | undefined) => {
      const { parentState } = getState(currentContext);
      if (handlers["onGridConfigurationChange"] && matchTypename("List", parentState?.["SelectedComponent"]?.value)) {
        const Config = setFn();
        if (Config) {
          // remove grid configuration change event to avoid infinite loop
          Config.Actions = Config?.Actions?.filter((a) => a?.Trigger !== "onGridConfigurationChange");
          handlers["onGridConfigurationChange"](new Event("change"), { Config });
        }
      }
    },
    [handlers, currentContext]
  );

  const onColumnsUpdate = useCallback(
    (event: IOnColumnUpdate) => {
      handleGridEditorEvent(() => {
        const columnsDefinitions = event?.api?.getColumnDefs();
        const ListColumns = fromColDefsToConfig(columnsDefinitions as CustomColDef[]);
        if (!isEqual(ListColumns, local.ListColumns.value)) {
          return { ...config, ListColumns };
        }
        return undefined;
      });
    },
    [config, local.ListColumns.value, handleGridEditorEvent]
  );

  const cols: ReadonlySignal<CustomColDef[]> = useComputed(() =>
    getColumns(config, local.Columns.value, local.ListColumns.value, currentContext)
  );

  const rowData: ReadonlySignal<IAnyObject[]> = useComputed(() => getRows(local.Rows.value));

  const gridOptions: GridOptions<IAnyObject> = useMemo(
    () => ({
      onColumnPivotModeChanged(event) {
        event.api.sizeColumnsToFit();
      },
      statusBar: {
        statusPanels: [
          { statusPanel: "agTotalRowCountComponent" },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent", align: "left" },
          { statusPanel: "agAggregationComponent", align: "right" },
        ],
      },
      rowSelection: {
        mode: "multiRow",
        isRowSelectable: () => true,
        checkboxes: (params) =>
          config.ShowCheckboxSelection ? checkboxSelection(params, GridRef.current?.api) : false,
        headerCheckbox: !!config.ShowCheckboxSelection,
        hideDisabledCheckboxes: true,
        selectAll: "currentPage",
      },
      selection: {
        mode: "multiRow",
        isRowSelectable: () => true,
        checkboxes: (params) =>
          config.ShowCheckboxSelection ? checkboxSelection(params, GridRef.current?.api) : false,
        headerCheckbox: !!config.ShowCheckboxSelection,
        hideDisabledCheckboxes: true,
        selectAll: "currentPage",
      },
      rowDragManaged: true,
      rowDragMultiRow: true,
      popupParent: GridContainerRef.current,
      pivotPanelShow: "always",
      suppressColumnMoveAnimation: false,
      enableRtl: /[?&]rtl=true/.test(window.location.search),
      enableFillHandle: true,
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 50,
      suppressClearOnFillReduction: false,
      groupSelectsChildren: true,
      suppressRowClickSelection: true,
      CsvExportParams: "",
      columnTypes: {
        currencyType: {
          useValueFormatterForExport: false,
        },
      },
      editType: "fullRow",
      getBusinessKeyForNode: (node) => (node.data ? node.data.name : ""),
      initialGroupOrderComparator: ({ nodeA, nodeB }) => {
        if (!nodeA?.key || !nodeB?.key) return 0;
        return nodeA?.key < nodeB?.key ? -1 : nodeA?.key > nodeB?.key ? 1 : 0;
      },
      advancedFilterParent: ContainerRef.current,
      excelStyles: excelStyles,
    }),
    []
  );

  const handleSidebarOpened = (e) => {
    if (e.source !== "sideBarButtonClicked" && e.visible && !config.SideBarOpenByDefault) {
      e.api.closeToolPanel();
    }
  };

  const onFirstDataRendered = useCallback(() => {
    if (config.EnableSideBar) {
      const colDefs = GridRef.current?.api.getColumnDefs() ?? [];
      for (const def of colDefs as unknown as CustomColDef[]) {
        if (def?.pivot) {
          GridRef.current?.api.setGridOption("pivotMode", true);
          break;
        }
      }
    }
    GridRef.current?.api.closeToolPanel();
  }, [config]);

  const onExpandOrCollapseAll = useCallback(
    (e: ExpandOrCollapseAllEvent) => {
      handleGridEditorEvent(() => ({
        ...config,
        GroupingsCollapsed: e.source === "collapseAll",
      }));
    },
    [config, handleGridEditorEvent]
  );

  const resolveServiceFields = useCallback((): ODataQueryOptions => {
    const service = local.Service.value;
    if (service?.Fields) {
      const { state, form, parentState } = getState(currentContext);

      const scope = getScope(currentContext, config, state, form, {}, parentState);
      const [params, isValid] = mapFields(service.Fields, scope);
      if (isValid) {
        const { Filter, GroupBy, Aggregate, OrderBy } = params;
        const queryRequest = new QueryRequest();
        queryRequest.Filter = Filter;
        queryRequest.GroupBy = GroupBy;
        queryRequest.Aggregate = Aggregate;
        queryRequest.OrderBy = OrderBy;
        const odataQuery = queryRequest.toODataQuery();
        return odataQuery as ODataQueryOptions;
      }
    }
    return {};
  }, [config, currentContext, local.Service.value]);

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      if (document.documentElement.clientWidth <= 1024) {
        event.api.closeToolPanel();
      }

      const provider = new OdataProvider({
        isCaseSensitiveStringFilter: true,
        callApi: async (options) => {
          const serviceConfig = { ...local.Service.value };
          return await callOdataQuery({ options, context: currentContext, serviceConfig, config });
        },
        beforeRequest(query) {
          const { $filter, $orderby } = resolveServiceFields();
          console.log(tag, "beforeRequest", { query, $filter, $orderby });
          if ($filter) query.filter = [...(query.filter ?? []), $filter ?? ""];
          if ($orderby) query.sort = [...(query.sort ?? []), $orderby ?? ""];
          query.select = cols.value.map((c) => c.field).filter((field) => field !== undefined);
        },
        afterLoadData(options, rowData) {
          console.log(tag, "afterLoadData", { options, rowData, event });
          rowData.length ? event.api.hideOverlay() : event.api.showNoRowsOverlay();
        },
      });
      event.api.setGridOption("serverSideDatasource", provider);

      removePageElements(testId ?? "", [".ag-paging-row-summary-panel, .ag-paging-description"]);
    },
    [cols.value, config, currentContext, local.Service.value, resolveServiceFields, testId]
  );

  return (
    <div data-testid={testId} data-type={config.__typename} ref={GridContainerRef} style={{ width: "100%" }}>
      <EditToolbar />
      <div ref={AdvanceFilterRef} />
      <div
        className="ag-theme-quartz"
        style={{
          width: "100%",
          height:
            config.GridHeight ??
            (config.GridLayout === "autoHeight" ? "100%" : `${defaultRowHeight * Number(local.RowsPerPage.value)}px`),
        }}
      >
        <AgGridReact
          domLayout={(config.GridLayout as DomLayoutType) ?? "normal"}
          sideBar={
            config.EnableSideBar
              ? {
                toolPanels: ["columns", "filters"],
                position: "right",
                defaultToolPanel: "",
                hiddenByDefault: false,
              }
              : undefined
          }
          onToolPanelVisibleChanged={handleSidebarOpened}
          getChartToolbarItems={() => ["chartDownload"]}
          defaultColDef={{
            floatingFilter: !!config.ShowFloatingFilter,
            enableRowGroup: true,
            editable: !!config.ReadOnlyEdit,
            filter: "agTextColumnFilter",
            flex: 1,
            enableValue: true,
            enablePivot: true,
            enableCellChangeFlash: true,
            cellClass: config.ColumnsClasses ?? undefined,
            headerClass: config.ColumnsHeaderClasses ?? undefined,
          }}
          getChildCount={(data) => data && data.childCount}
          pivotMode={!!config.PivotMode}
          ref={GridRef}
          rowData={rowData.value}
          columnDefs={cols.value}
          loading={local.Loading.value}
          getRowId={getRowId}
          autoSizeStrategy={
            config.RowsAutoSizeStrategy
              ? ({
                type: config.RowsAutoSizeStrategy ?? "fitGridWidth",
              } as SizeColumnsToFitGridStrategy)
              : undefined
          }
          autoGroupColumnDef={defaultGroupColumn(config)}
          rowGroupPanelShow={(config.ShowGroupingPanel as RowGroupPanelShowOptions) ?? undefined}
          suppressAggFuncInHeader
          groupDefaultExpanded={config.GroupingsCollapsed ?? true ? 0 : -1}
          onRowClicked={handleOnRowClicked}
          onColumnValueChanged={onColumnsUpdate}
          onSelectionChanged={onSelectionChanged}
          rowHeight={config.RowHeight ?? defaultRowHeight}
          paginationPageSize={Number(local.RowsPerPage.value)}
          paginationAutoPageSize={config.PaginationAutoPageSize ?? undefined}
          paginationPageSizeSelector={
            config.ShowPageSizeSelector ? addRowPerPageToSelector(local.RowsPerPage.value) : false
          }
          pagination={!!config.ShowPagination}
          gridOptions={gridOptions as unknown as GridOptions<unknown>}
          defaultCsvExportParams={defaultExportParams as CsvExportParams}
          defaultExcelExportParams={defaultExportParams as unknown as ExcelExportParams}
          enableAdvancedFilter={!!config.ShowAdvancedFilter}
          advancedFilterParent={AdvanceFilterRef.current}
          onFirstDataRendered={onFirstDataRendered}
          readOnlyEdit={!!config.ReadOnlyEdit}
          onRowValueChanged={onRowValueChanged}
          onExpandOrCollapseAll={onExpandOrCollapseAll}
          onColumnPinned={onColumnsUpdate}
          onColumnMoved={debounce(onColumnsUpdate, 300)}
          onColumnPivotChanged={onColumnsUpdate}
          onColumnVisible={onColumnsUpdate}
          cacheBlockSize={Number(local.RowsPerPage.value)}
          maxBlocksInCache={10}
          onGridReady={onGridReady}
          rowModelType={(config.RowModelType as RowModelType) ?? "clientSide"}
          aggFuncs={{
            sum: (params: IAggFuncParams) => {
              let sum = 0;
              params.values.forEach((value) => {
                if (typeof value === "number" || params.colDef.cellDataType === "number") sum += toNumber(value);
              });
              return sum ?? undefined;
            },
            max: (params: IAggFuncParams) => {
              return max(params.values) ?? undefined;
            },
            min: (params: IAggFuncParams) => {
              return min(params.values) ?? undefined;
            },
          }}
        />
      </div>
      <BottomButtons />
    </div>
  );
}
