import * as graphql from "../../../resolvers-types";
import { generateUID } from "../../library/utils";

export const componentLibrary: graphql.ComponentLibrary = {
  __typename: "ComponentLibrary",
  Id: generateUID(),
  Name: "Components",
  Bindings: {
    Components:
      "State.Components?State.Components.map(function(c) \
      { ({__typename: 'LibraryItem', Id: c.Id,Name: c.Name, \
        Description: c.Description, \
        Category: c.Category, \
        Template: \
        { \
          __typename: 'Reference', \
          Id: uid(), \
          ReferenceType: 'Component', \
          ReferenceId: c.Id, \
          ReferenceSource: c.Source \
        } \
      }) }):[]",
  },

  Components: [],
};

export const controlLibrary: graphql.ComponentLibrary = {
  __typename: "ComponentLibrary",
  Id: generateUID(),
  Name: "Items",
  Components: [
    // {
    //   __typename: "LibraryItem",
    //   Id: generateUID(),
    //   Name: "Free Form Container",
    //   Icon: { IconName: "CropFreeOutlined" },
    //   Type: "Items",
    //   Description: "A description for SimpleContainer",
    //   Category: "Containers",
    //   SubCategory: "Some SubCategory",
    //   InitialWidth: 5,
    //   InitialHeight: 25,
    //   Template: {
    //     __typename: "SimpleContainer",
    //     Id: generateUID(),
    //     Items: [],
    //     Layout: {
    //       __typename: "FreeFormLayout",
    //     },
    //   },
    // },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Vertical Container",
      Icon: { IconName: "AspectRatioOutlined" },
      Type: "Items",
      Description: "A description for FlexContainer",
      Category: "Containers",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        __typename: "SimpleContainer",
        Id: generateUID(),
        Items: [],
        IgnoreLayout: true,
        Style: {
          display: "flex",
          flexDirection: "column",
          minWidth: "20px",
          minHeight: "20px",
        },
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Horizontal Container",
      Icon: { IconName: "AspectRatioOutlined" },
      Type: "Items",
      Description: "A description for FlexContainer",
      Category: "Containers",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        __typename: "SimpleContainer",
        Id: generateUID(),
        Items: [],
        IgnoreLayout: true,
        Style: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          minWidth: "20px",
          minHeight: "20px",
        },
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Button",
      Icon: { IconName: "SmartButtonOutlined" },
      Type: "Items",
      Description: "A description for Button",
      Category: "Controls",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        Id: generateUID(),
        __typename: "Button",
        Label: "Button",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Label",
      Icon: { IconName: "AbcOutlined" },
      Type: "Items",
      Description: "A description for Label",
      Category: "Controls",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        Id: generateUID(),
        __typename: "Label",
        Value: "Label",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Link",
      Icon: { IconName: "Link" },
      Type: "Items",
      Description: "A description for Link",
      Category: "Controls",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        Id: generateUID(),
        __typename: "Link",
      },
    }, //
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Html",
      Icon: { IconName: "Language" },
      Type: "Items",
      Description: "A description for HTML",
      Category: "Controls",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        Id: generateUID(),
        Icon: { IconName: "Language" },
        __typename: "Html",
        Value: "</b>Html<b>",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Multi-Select",
      Icon: { IconName: "ArrowDropDownCircleOutlined" },
      Type: "Items",
      Description: "A description for MultiSelect",
      Category: "Controls",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        Id: generateUID(),
        __typename: "MultiSelect",
        Label: "MultiSelect",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Checkbox",
      Icon: { IconName: "CheckBoxOutlined" },
      Type: "Items",
      Description: "A description for Checkbox",
      Category: "Controls",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        Id: generateUID(),
        __typename: "Checkbox",
        Label: "Checkbox",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Slider",
      Icon: { IconName: "LinearScaleOutlined" },
      Type: "Items",
      Description: "A description for Slider",
      Category: "Controls",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        Id: generateUID(),
        __typename: "Slider",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Switch",
      Icon: { IconName: "ToggleOffOutlined" },
      Type: "Items",
      Description: "A description for Switch",
      Category: "Controls",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        Id: generateUID(),
        __typename: "Switch",
        Label: "Label for Switch",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Input Field",
      Icon: { IconName: "Crop169Outlined" },
      Type: "Items",
      Description: "A description for InputField",
      Category: "Controls",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        Id: generateUID(),
        __typename: "InputField",
        Label: "Label for InputField",
        Style: { margin: "0px", width: "100%" },
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Date Picker",
      Icon: { IconName: "CalendarToday" },
      Type: "Items",
      Description: "A description for Date Picker",
      Category: "Controls",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        Id: generateUID(),
        __typename: "DatePicker",
        Label: "Date",
        Style: { margin: "0px", width: "100%" },
      },
    },

    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Radio Group",
      Icon: { IconName: "RadioButtonChecked" },
      Type: "Items",
      Description: "A description for RadioGroup",
      Category: "Controls",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        Id: generateUID(),
        __typename: "RadioGroup",
        MultiSelectPossibleValues: ["Option1", "Option2", "Option3"],
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Avatar",
      Icon: { IconName: "AccountCircleOutlined" },
      Type: "Items",
      Description: "A description for Image",
      Category: "Controls",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        Id: generateUID(),
        __typename: "Avatar",
      } as graphql.Avatar,
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Image",
      Icon: { IconName: "InsertPhotoOutlined" },
      Type: "Items",
      Description: "A description for Image",
      Category: "Controls",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        Id: generateUID(),
        __typename: "Image",
        // Width: "100%",
        // Height: "auto",
        Value: "communify-finance-logo.webp",
      } as graphql.Image,
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "File Upload",
      Icon: { IconName: "UploadFileOutlined" },
      Type: "Items",
      Description: "A description for FileUpload",
      Category: "Controls",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        Id: generateUID(),
        __typename: "FileUpload",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Document Viewer",
      Icon: { IconName: "FindInPage" },
      Type: "Items",
      Description: "A description for DocumentViewer",
      Category: "Controls",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        Id: generateUID(),
        __typename: "DocumentViewer",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Tab Container",
      Icon: { IconName: "TabOutlined" },
      Type: "Items",
      Description: "A description for TabContainer",
      Category: "Containers",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        __typename: "TabContainer",
        Id: generateUID(),
        BoxStyle: { width: "100%" },
        Items: [
          {
            __typename: "TabItem",
            Id: generateUID(),
            Title: "Tab 1",
            Items: [
              {
                __typename: "SimpleContainer",
                Id: generateUID(),
                Items: [],
                IgnoreLayout: true,
                Style: {
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "20px",
                  minHeight: "20px",
                },
              },
            ],
          },
          {
            __typename: "TabItem",
            Id: generateUID(),
            Title: "Tab 2",
            Items: [
              {
                __typename: "SimpleContainer",
                Id: generateUID(),
                Items: [],
                IgnoreLayout: true,
                Style: {
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "20px",
                  minHeight: "20px",
                },
              },
            ],
          },

          {
            __typename: "SimpleContainer",
            Id: generateUID(),
            Items: [],
            IgnoreLayout: true,
            Style: {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              minHeight: "20px",
              flex: 1,
            },
          },
        ],
      },
    },

    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Repeater",
      Icon: { IconName: "AutoAwesomeMotionOutlined" },
      Type: "Items",
      Description: "A description for Repeater",
      Category: "Containers",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        __typename: "Repeater",
        Id: generateUID(),
        Variant: "Flex",
        Items: [
          {
            __typename: "SimpleContainer",
            Id: generateUID(),
            Items: [],
            IgnoreLayout: true,
            Style: {
              display: "flex",
              flexDirection: "column",
              minWidth: "20px",
              minHeight: "20px",
            },
          },
        ],
        Layout: {
          Id: generateUID(),
          __typename: "FlexLayout",
          Direction: "column",
        },
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Data Grid",
      Icon: { IconName: "TableChartOutlined" },
      Type: "Items",
      Description: "Grid with groupping filtering",
      Category: "Containers",
      SubCategory: "Data",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        __typename: "List",
        Id: generateUID(),
        ShowExport: true,
        ShowFloatingFilter: true,
        ShowQuickFilter: true,
        Style: { width: "100%" },
        GridLayout: "normal",
        ListColumns: [
          {
            __typename: "ListColumn",
            Id: generateUID(),
            HeaderName: "Id",
            Name: "Id",
            IsPrimaryKey: true,
          },
          {
            __typename: "ListColumn",
            Id: generateUID(),
            HeaderName: "Name",
            Name: "Name",
          },
          {
            __typename: "ListColumn",
            Id: generateUID(),
            HeaderName: "Ticker",
            Name: "Ticker",
          },
          {
            __typename: "ListColumn",
            Id: generateUID(),
            HeaderName: "Quote",
            Name: "Quote",
          },
        ],
        Rows: [
          {
            Id: 1,
            Name: "NVIDIA",
            Ticker: "NVDA",
            Quote: 67.87,
            FileExtension: "pdf",
          },
          {
            Id: 2,
            Name: "Microsoft",
            Ticker: "MSFT",
            Quote: 152.17,
            FileExtension: "png",
          },
          {
            Id: 3,
            Name: "Apple",
            Ticker: "AAPL",
            Quote: 200.15,
            FileExtension: "docx",
          },
          {
            Id: 4,
            Name: "Meta",
            Ticker: "META",
            Quote: 537.95,
            FileExtension: "xlsx",
          },
        ],
        Items: [
          {
            __typename: "SimpleContainer",
            Id: "mi65126nd0",
            IgnoreLayout: true,
            Style: {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              minHeight: "20px",
              flex: 1,
              justifyContent: "space-between",
            },
            Items: [
              {
                __typename: "ListExportButton",
                EnableCSV: true,
                EnableExcel: true,
                Id: generateUID(),
              },
              {
                __typename: "ListQuickFilter",
                Actions: [],
                Adornment: {
                  Position: "Start",
                  Icon: {
                    IconName: "Search",
                  },
                },
                Id: generateUID(),
              },
            ],
          },
        ],
      },
    },
    // {
    //   __typename: "LibraryItem",
    //   Id: generateUID(),
    //   Name: "Data Grid",
    //   Icon: { IconName: "TableChartOutlined" },
    //   Type: "Items",
    //   Description: "A description for Data Grid",
    //   Category: "Container",
    //   SubCategory: "Some SubCategory",
    //   InitialWidth: 5,
    //   InitialHeight: 25,
    //   Template: {
    //     __typename: "List",
    //     Id: generateUID(),
    //     ShowExport: true,
    //     ShowFilter: true,
    //     Style: { width: "100%" },
    //     ListColumns: [
    //       {
    //         __typename: "ListColumn",
    //         Id: generateUID(),
    //         HeaderName: "Id",
    //         Name: "Id",
    //         IsPrimaryKey: true,
    //       },
    //       {
    //         __typename: "ListColumn",
    //         Id: generateUID(),
    //         HeaderName: "Column 1",
    //         Name: "Column1",
    //       },
    //       {
    //         __typename: "ListColumn",
    //         Id: generateUID(),
    //         HeaderName: "Column 2",
    //         Name: "Column2",
    //       },
    //     ],
    //     Rows: [],
    //   },
    // },

    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Fin Basic Chart",
      Icon: { IconName: "ShowChart" },
      Type: "Items",
      Description: "Fin Basic Chart",
      Category: "Fin Apps",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        __typename: "FinApp",
        Id: generateUID(),
        Type: "basic-chart",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Fin Company Profile",
      Icon: { IconName: "Business" },
      Type: "Items",
      Description: "Fin Company Profile",
      Category: "Fin Apps",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        __typename: "FinApp",
        Id: generateUID(),
        Type: "company-profile",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Fin Competitors",
      Icon: { IconName: "Group" },
      Type: "Items",
      Description: "Fin Competitors",
      Category: "Fin Apps",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        __typename: "FinApp",
        Id: generateUID(),
        Type: "competitors",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Fin Estimates",
      Icon: { IconName: "TrendingUp" },
      Type: "Items",
      Description: "Fin Estimates",
      Category: "Fin Apps",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        __typename: "FinApp",
        Id: generateUID(),
        Type: "estimates",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Fin Events",
      Icon: { IconName: "Event" },
      Type: "Items",
      Description: "Fin Events",
      Category: "Fin Apps",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        __typename: "FinApp",
        Id: generateUID(),
        Type: "events",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Fin Financial Health",
      Icon: { IconName: "HealthAndSafety" },
      Type: "Items",
      Description: "Fin Financial Health",
      Category: "Fin Apps",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        __typename: "FinApp",
        Id: generateUID(),
        Type: "financial-health",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Fin News",
      Icon: { IconName: "Article" },
      Type: "Items",
      Description: "Fin News",
      Category: "Fin Apps",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        __typename: "FinApp",
        Id: generateUID(),
        Type: "news",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Fin Ownership",
      Icon: { IconName: "AssignmentInd" },
      Type: "Items",
      Description: "Fin Ownership",
      Category: "Fin Apps",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        __typename: "FinApp",
        Id: generateUID(),
        Type: "ownership",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Fin Quote",
      Icon: { IconName: "FormatQuote" },
      Type: "Items",
      Description: "Fin Quote",
      Category: "Fin Apps",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        __typename: "FinApp",
        Id: generateUID(),
        Type: "quote",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Fin Ratios",
      Icon: { IconName: "BarChart" },
      Type: "Items",
      Description: "Fin Ratios",
      Category: "Fin Apps",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        __typename: "FinApp",
        Id: generateUID(),
        Type: "ratios",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Fin Search",
      Icon: { IconName: "Search" },
      Type: "Items",
      Description: "Fin Search",
      Category: "Fin Apps",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        __typename: "FinApp",
        Id: generateUID(),
        Type: "search",
      },
    },
    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Fin Top Executives",
      Icon: { IconName: "SupervisorAccount" },
      Type: "Items",
      Description: "Fin Top Executives",
      Category: "Fin Apps",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        __typename: "FinApp",
        Id: generateUID(),
        Type: "top-executives",
      },
    },

    {
      __typename: "LibraryItem",
      Id: generateUID(),
      Name: "Chart",
      Icon: { IconName: "Addchart" },
      Type: "Items",
      Description: "A description for Chart",
      Category: "Containers",
      SubCategory: "Some SubCategory",
      InitialWidth: 5,
      InitialHeight: 25,
      Template: {
        Id: generateUID(),
        __typename: "Chart",
        ChartLibrary: "Highcharts",
        ChartMetadata: {
          chart: {
            type: "bar",
            options3d: {
              enabled: true,
              alpha: 10,
              beta: 10,
              depth: 10,
              viewDistance: 2500,
              fitToPlot: true,
            },
          },
          title: {
            text: "3D Cylinder Chart with Stacked Slices (Horizontal)",
          },
          xAxis: {
            categories: ["Cylinder"],
            labels: {
              enabled: false,
            },
            visible: false,
          },
          yAxis: {
            title: {
              text: "Value",
            },
            visible: false,
          },
          plotOptions: {
            series: {
              stacking: "normal",
              depth: 40,
              pointPadding: 0,
              groupPadding: 0,
              borderWidth: 0,
              dataLabels: {
                enabled: true,
                format: "{series.name}",
              },
            },
            bar: {
              depth: 100,
              radialWidth: 1,
              shapeValue: 1,
              borderRadius: 3,
            },
          },
          legend: {
            enabled: false,
          },
          series: [
            {
              name: "Slice 1",
              data: [100],
            },
            {
              name: "Slice 2",
              data: [20],
            },
            {
              name: "Slice 3",
              data: [15],
            },
          ],
        },
        TransposeRowsToSeries: true,
        TransposeRowNames: ["AssetClassLevel1"],
        TransposeRowValueFrom: "BaseCurrencyEndMarketValue",
      },
    },
    // {
    //   __typename: "LibraryItem",
    //   Id: generateUID(),
    //   Name: "List",
    //   Icon: { IconName: "TableRows" },
    //   Type: "Items",
    //   Description: "A description for List",
    //   Category: "Some Category",
    //   SubCategory: "Some SubCategory",
    //   InitialWidth: 5,
    //   InitialHeight: 25,
    //   Template: {
    //     Id: generateUID(),
    //     __typename: "List",
    //   },
    // },
  ],

  // {
  //   __typename: "Component",
  //   Id: generateUID(),
  //   Name: "AI Dialog",
  //   Icon: { IconName: "Tungsten" },
  //   Type: "Items",
  //   Description: "A description for AIDialog",
  //   Category: "Some Category",
  //   SubCategory: "Some SubCategory",
  //   InitialWidth: 5,
  //   InitialHeight: 25,
  //   ComponentContainer: {
  //     __typename: "SimpleContainer",
  //     Id: generateUID(),
  //     UniqueName: "AIDialogContainer",
  //     Items: [
  //       {
  //         Typename: "Form",
  //         Id: generateUID(),
  //         Items: [
  //           {
  //             Id: generateUID(),
  //             __typename: "AIDialog",
  //             Label: "Label for AIDialog",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // },
  // {
  //   __typename: "Component",
  //   Id: generateUID(),
  //   Name: "Visible Menu",
  //   Icon: { IconName: "Menu" },
  //   Type: "Items",
  //   Description: "A description for VisibleMenu",
  //   Category: "Some Category",
  //   SubCategory: "Some SubCategory",
  //   InitialWidth: 5,
  //   InitialHeight: 25,
  //   ComponentContainer: {
  //     __typename: "SimpleContainer",
  //     Id: generateUID(),
  //     UniqueName: "VisibleMenuContainer",
  //     Items: [
  //       {
  //         Typename: "Form",
  //         Id: generateUID(),
  //         Items: [
  //           {
  //             Id: generateUID(),
  //             __typename: "VisibleMenu",
  //             Label: "Label for VisibleMenu",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // },

  // {
  //   __typename: "Component",
  //   Id: generateUID(),
  //   Name: "Radio Group",
  //   Icon: { IconName: "RadioButtonChecked" },
  //   Type: "Items",
  //   Description: "A description for RadioGroup",
  //   Category: "Some Category",
  //   SubCategory: "Some SubCategory",
  //   InitialWidth: 5,
  //   InitialHeight: 25,
  //   ComponentContainer: {
  //     __typename: "SimpleContainer",
  //     Id: generateUID(),
  //     UniqueName: "RadioGroupContainer",
  //     Items: [
  //       {
  //         Typename: "Form",
  //         Id: generateUID(),
  //         Items: [
  //           {
  //             Id: generateUID(),
  //             __typename: "RadioGroup",
  //             Label: "Label for RadioGroup",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // },

  // {
  //   __typename: "Component",
  //   Id: generateUID(),
  //   Name: "Code Editor",
  //   Icon: { IconName: "Code" },
  //   Type: "Items",
  //   Description: "A description for CodeEditor",
  //   Category: "Some Category",
  //   SubCategory: "Some SubCategory",
  //   InitialWidth: 5,
  //   InitialHeight: 25,
  //   ComponentContainer: {
  //     __typename: "SimpleContainer",
  //     Id: generateUID(),
  //     UniqueName: "CodeEditorContainer",
  //     Items: [
  //       {
  //         Typename: "Form",
  //         Id: generateUID(),
  //         Items: [
  //           {
  //             Id: generateUID(),
  //             __typename: "CodeEditor",
  //             Label: "Label for CodeEditor",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // },
  // {
  //   __typename: "Component",
  //   Id: generateUID(),
  //   Name: "Form",
  //   Icon: { IconName: "Ballot" },
  //   Type: "Items",
  //   Description: "A description for Form",
  //   Category: "Some Category",
  //   SubCategory: "Some SubCategory",
  //   InitialWidth: 5,
  //   InitialHeight: 25,
  //   ComponentContainer: {
  //     __typename: "SimpleContainer",
  //     Id: generateUID(),
  //     UniqueName: "FormContainer",
  //     Items: [
  //       {
  //         Typename: "Form",
  //         Id: generateUID(),
  //         Items: [
  //           {
  //             Id: generateUID(),
  //             __typename: "Form",
  //             Label: "Label for Form",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // },

  // {
  //   __typename: "Component",
  //   Id: generateUID(),
  //   Name: "Tree List",
  //   Icon: { IconName: "AccountTree" },
  //   Type: "Items",
  //   Description: "A description for TreeList",
  //   Category: "Some Category",
  //   SubCategory: "Some SubCategory",
  //   InitialWidth: 5,
  //   InitialHeight: 25,
  //   ComponentContainer: {
  //     __typename: "SimpleContainer",
  //     Id: generateUID(),
  //     UniqueName: "TreeListContainer",
  //     Items: [
  //       {
  //         Typename: "Form",
  //         Id: generateUID(),
  //         Items: [
  //           {
  //             Id: generateUID(),
  //             __typename: "TreeList",
  //             Label: "Label for TreeList",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // },
  // {
  //   __typename: "Component",
  //   Id: generateUID(),
  //   Name: "Feed",
  //   Icon: { IconName: "ViewStream" },
  //   Type: "Items",
  //   Description: "A description for Feed",
  //   Category: "Some Category",
  //   SubCategory: "Some SubCategory",
  //   InitialWidth: 5,
  //   InitialHeight: 25,
  //   ComponentContainer: {
  //     __typename: "SimpleContainer",
  //     Id: generateUID(),
  //     UniqueName: "FeedContainer",
  //     Items: [
  //       {
  //         Typename: "Form",
  //         Id: generateUID(),
  //         Items: [
  //           {
  //             Id: generateUID(),
  //             __typename: "Feed",
  //             Label: "Label for Feed",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // },
  // {
  //   __typename: "Component",
  //   Id: generateUID(),
  //   Name: "Repeater",
  //   Icon: { IconName: "AutoAwesomeMotion" },
  //   Type: "Applications",
  //   Description: "A description for Repeater",
  //   Category: "Some Category",
  //   SubCategory: "Some SubCategory",
  //   InitialWidth: 5,
  //   InitialHeight: 25,
  //   ComponentContainer: {
  //     __typename: "SimpleContainer",
  //     Id: generateUID(),
  //     UniqueName: "RepeaterContainer",
  //     Items: [
  //       {
  //         Typename: "Repeater",
  //         UniqueName: generateUID(),
  //         Id: generateUID(),
  //       },
  //     ],
  //   },
  // },
  // {
  //   __typename: "Component",
  //   Id: generateUID(),
  //   Name: "IFrame",
  //   Icon: { IconName: "FilterFrames" },
  //   Type: "Items",
  //   Description: "A description for IFrame",
  //   Category: "Some Category",
  //   SubCategory: "Some SubCategory",
  //   InitialWidth: 5,
  //   InitialHeight: 25,
  //   ComponentContainer: {
  //     __typename: "SimpleContainer",
  //     Id: generateUID(),
  //     UniqueName: "IFrameContainer",
  //     Items: [
  //       {
  //         Typename: "Form",
  //         Id: generateUID(),
  //         Items: [
  //           {
  //             Id: generateUID(),
  //             __typename: "IFrame",
  //             Label: "Label for IFrame",
  //             Source: "",
  //           } as graphql.IFrame,
  //         ],
  //       },
  //     ],
  //   },
  // },
  // {
  //   __typename: "Component",
  //   Id: generateUID(),
  //   Name: "Image",
  //   Icon: { IconName: "InsertPhoto" },
  //   Type: "Items",
  //   Description: "A description for Image",
  //   Category: "Some Category",
  //   SubCategory: "Some SubCategory",
  //   InitialWidth: 5,
  //   InitialHeight: 25,
  //   ComponentContainer: {
  //     __typename: "SimpleContainer",
  //     Id: generateUID(),
  //     UniqueName: "ImageContainer",
  //     Items: [
  //       {
  //         Typename: "Form",
  //         Id: generateUID(),
  //         Items: [
  //           {
  //             Id: generateUID(),
  //             __typename: "Image",
  //             Label: "Label for Image",
  //             Value: "",
  //           } as graphql.Image,
  //         ],
  //       },
  //     ],
  //   },
  // },

  Actions: [
    // {
    //   Trigger: "onComponentSelected",
    //   CommandSet: {
    //     FirstCommandId: "1",
    //     ExecuteCommandsInParallel: false,
    //     Commands: [
    //       {
    //         Id: "1",
    //         Instruction: {
    //           Name: "SendMessageToChild",
    //         },
    //         Parameters: [
    //           {
    //             Name: "IFrame",
    //             Value: "EditorIFrame",
    //           },
    //           {
    //             Name: "Type",
    //             Value: "addControl",
    //           },
    //           {
    //             Name: "Bindings",
    //             Value: {
    //               Control: "Event",
    //             },
    //           },
    //           {
    //             Name: "Actions",
    //             Value: [
    //               {
    //                 Trigger: "addControl",
    //                 CommandSet: {
    //                   FirstCommandId: "1",
    //                   ExecuteCommandsInParallel: false,
    //                   Commands: [
    //                     {
    //                       Id: "1",
    //                       Instruction: {
    //                         Name: "SetState",
    //                       },
    //                       Parameters: [
    //                         {
    //                           // TODO: fix these bindings, we don't need them to do this any more
    //                           Name: "Bindings",
    //                           Value: {
    //                             "State.Container.FormApplications":
    //                               "(State.Container.FormApplications || []).concat([Event.Control.ComponentContainer])",
    //                             "State.LatestControl": "State.Container.FormApplications.slice(-1)[0]",
    //                             "State.Page.Containers": "(State.Page.Containers || []).concat(State.LatestControl)",
    //                           },
    //                         },
    //                       ],
    //                     },
    //                   ],
    //                 },
    //               },
    //             ],
    //           },
    //         ],
    //       },
    //     ],
    //   },
    // },
  ],
};
