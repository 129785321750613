/* eslint-disable @typescript-eslint/no-explicit-any */
// PEG Expression Interpreter
import { parse } from "./parser";
import { Signal, signal } from "@preact/signals-react";
import { clone, has, isArray, isEmpty, isNil, isObject, merge, cloneDeep, map } from "lodash-es";
import {
  formatDate,
  formatNumber,
  generateGuid,
  generateUID,
  isIndex,
  isNullOrEmpty,
  isSignal,
  makeSlug,
} from "./utils";
import {
  buildTreeItems,
  cloneJsonWithIDs,
  getNodeChildRestrictions,
  getNodeParentRestrictions,
  isComponent,
  isContainer,
  isMenu,
  mapMetadataRec,
} from "./metadataUtils";
import { LogTag, log } from "./logger";
import {
  convertOpenAPIFieldsToPropertiesEditor,
  convertServiceResultToPropertiesEditor,
  extractFieldList,
  getPropertiesEditorDataFromServiceResult,
  getServiceFromPath,
  parseOpenAPIServices,
} from "../generators/codeGeneration/openApiParser";
import { Instruction } from "@atlaskit/pragmatic-drag-and-drop-hitbox/dist/types/tree-item";
import { moveMetadataItem } from "./actions";
import { getFormData } from "./dataService";
import { generateFeature } from "../sampleData/ngEditorMenu";
import { isNative } from "./native";

export const astCache: { [key: string]: object } = {};

const tag: LogTag = "interpreter";

export function getAst(expr) {
  if (!astCache[expr]) {
    const start = performance.now();
    astCache[expr] = parse(expr ?? "");
    const end = performance.now();
    const took = end - start;
    if (took > 5) {
      // ms
      log.info(tag, "parse:", expr, `took: ${end - start}ms`);
    }
  }
  return astCache[expr];
}

export type SetFn = (left: any, idx: any, right: any) => void;

export type InterpreterOptions = {
  merge: boolean;
  setFn?: SetFn;
};

type InterpreterOptionsInner = {
  merge: boolean;
  setFn: SetFn;
};

const defaultOptions = { merge: false, setFn: undefined };

export function getExprValue(
  expr: string,
  scope: object,
  defaultValue: any,
  returnSignal = false,
  opts: InterpreterOptions = defaultOptions
): any {
  return interpret(expr, scope, defaultValue, false, returnSignal, opts);
}

export function setExprValue(expr: string, scope: object, value: any, opts: InterpreterOptions = defaultOptions): any {
  return interpret(expr, scope, value, true, false, opts);
}

const assignFn = (left, idx, right) => {
  left[idx] = right;
};

const mergeFn = (left, idx, right) => {
  merge(left[idx], right);
};

function interpret(
  expr: string,
  scope: object,
  defaultValue: any,
  setter: boolean,
  returnSignal: boolean,
  opts: InterpreterOptions = defaultOptions
): any {
  try {
    // console.group("interpret", expr, defaultValue);
    const ast: any = getAst(expr);

    // Init options
    const innerOpts = merge(
      {
        setFn: opts.merge ? mergeFn : assignFn,
      },
      opts
    );

    const start = performance.now();
    const result = evaluateAst(ast, scope, defaultValue, setter, returnSignal, innerOpts);
    const end = performance.now();
    const took = end - start;
    // if (took > 2) {
    // ms
    log.info(tag, "evaluate", expr, `took: ${end - start}ms`);
    // }
    return result;
  } catch (e: any) {
    log.error(tag, "error", expr, e.message);
    return null;
  }
}

function findChild(obj, children, prop, value, setter, nextValue, level, topSignal, opts: InterpreterOptionsInner) {
  const props = isArray(children) ? children : [children];
  for (const childProp of props) {
    if (obj[childProp]) {
      if (isArray(obj[childProp])) {
        for (let i = 0; i < obj[childProp].length; i++) {
          const child = obj[childProp][i];
          if (child[prop] === value) {
            return { parent: obj, child: childProp, index: i, type: "array" };
          }
          const r = findChild(child, children, prop, value, setter, nextValue, level, topSignal, opts);
          if (r !== null && r.child !== null) return r;
        }
      } else if (isObject(obj[childProp]) && obj[childProp][prop] === value) {
        return { parent: obj, child: childProp, index: null, type: "object" };
      } else {
        const r = findChild(obj[childProp], children, prop, value, setter, nextValue, level, topSignal, opts);
        if (r !== null) return r;
      }
    }
  }
  return { parent: obj, child: null, index: 0, type: "object" };
}

function findMeta(obj, value, setter, nextValue, level, topSignal, opts: InterpreterOptionsInner) {
  const node = findChild(
    obj,
    ["Items", "ComponentContainer", "Tabs", "Features"],
    "Id",
    value,
    setter,
    nextValue,
    level,
    topSignal,
    opts
  );

  if (setter && level == 0) {
    if (node.type == "array") {
      opts.setFn(node?.parent?.[node.child], node.index, nextValue);
    } else if (node.type == "object") {
      opts.setFn(node?.parent, node.child, nextValue);
    }
    if (topSignal) {
      topSignal.value = clone(topSignal.value);
    }
  }

  return node?.parent?.[node.child][node.index];
}

function generatePageWithClonedMeta(obj, selectedNode, clonedNode, opts: InterpreterOptionsInner) {
  if (!selectedNode || !clonedNode) {
    return obj;
  }
  // recriate cloned component on paste, to avoid duplicated ids on multiple paste from same copied component
  const clonedComponent = cloneJsonWithIDs(clonedNode, {}, { originalId: clonedNode.Id });

  const original = findChild(
    obj,
    ["Items", "ComponentContainer", "Tabs"],
    "Id",
    clonedComponent["originalId"],
    null,
    null,
    null,
    null,
    opts
  );
  const selected = findChild(
    obj,
    ["Items", "ComponentContainer", "Tabs"],
    "Id",
    selectedNode["Id"],
    null,
    null,
    null,
    null,
    opts
  );

  const originalNode = original?.parent[original?.child][original?.index] ?? null;
  const nodeRestrictions = getNodeChildRestrictions(selectedNode);
  const parentRestrictions = getNodeChildRestrictions(selected?.parent);
  const clonedRestrictions = getNodeParentRestrictions(clonedComponent);

  function getMoveMetadataItems(arg1, arg2, arg3) {
    return {
      DropData: {
        location: {
          current: {
            dropTargets: [
              {
                data: { id: arg1?.Id },
              },
            ],
          },
        },
        source: {
          data: {
            id: arg2.Id,
          },
        },
      },
      Page: arg3,
    };
  }

  // if cloned component is of __typename = component, don't do anything, this is not allowed
  if (isComponent(clonedComponent)) {
    return obj;
  }
  // if selected = original copied node => add copied node below selected node
  if (selectedNode?.Id === originalNode?.Id) {
    const instruction: Instruction = { type: "reorder-below", currentLevel: 0, indentPerLevel: 0 };
    const { Page } = moveMetadataItem(
      getMoveMetadataItems(selectedNode, clonedComponent, obj),
      { ...clonedComponent, id: clonedComponent?.Id },
      instruction
    );
    return Page;
  }

  if (selectedNode?.Id !== originalNode?.Id && !isContainer(selectedNode)) {
    // when selectedNote is not equal original copied node, check parent restrictions
    if (!!parentRestrictions && parentRestrictions !== clonedComponent?.__typename) {
      return obj;
    }
    // check cloned component parent restrictions
    if (!!clonedRestrictions && clonedRestrictions !== selected?.parent?.__typename) {
      return obj;
    }
    const instruction: Instruction = { type: "reorder-below", currentLevel: 0, indentPerLevel: 0 };
    const { Page } = moveMetadataItem(
      getMoveMetadataItems(selectedNode, clonedComponent, obj),
      { ...clonedComponent, id: clonedComponent?.Id },
      instruction
    );
    return Page;
  }

  // selected != copied and selected is container, create new component as last item of selected container
  if (selectedNode?.Id !== originalNode?.Id && isContainer(selectedNode)) {
    // when selected node is a container, check it's child restrictions
    if (!!nodeRestrictions && nodeRestrictions !== clonedComponent?.__typename) {
      return obj;
    }
    const instruction: Instruction = { type: "make-child", currentLevel: 0, indentPerLevel: 0 };
    const { Page } = moveMetadataItem(
      getMoveMetadataItems(selectedNode, clonedComponent, obj),
      { ...clonedComponent, id: clonedComponent?.Id },
      instruction
    );
    return Page;
  }

  return obj;
}

function deleteMeta(obj, value, setter, nextValue, level, topSignal, opts: InterpreterOptionsInner) {
  const node = findChild(
    obj,
    ["Items", "ComponentContainer", "Tabs", "Features"],
    "Id",
    value,
    setter,
    nextValue,
    level,
    topSignal,
    opts
  );
  let deleted = null;
  if (setter && level == 0) {
    if (node.type == "array") {
      deleted = node.parent[node.child].splice(node.index, 1);
    } else if (node.type == "object") {
      deleted = node.parent[node.child];
      delete node.parent[node.child];
    }
    if (topSignal) {
      topSignal.value = clone(topSignal.value);
    }
  }
  return deleted;
}

function isSimpleType(o: any) {
  return o === null || typeof o !== "object" || o === undefined;
}

function getNGObjectType(param: any) {
  if (isSimpleType(param)) return "SimpleValue";

  // Check if the parameter is an array
  if (Array.isArray(param)) {
    for (let i = 0; i < param.length; i++) {
      const subType = getNGObjectType(param[i]);

      if (subType !== "SingleRecord") return "ComplexObject";
    }

    return "MultipleRows";
  }

  // Check if the parameter is an object but not null
  if (param !== null && typeof param === "object") {
    // Check every property to ensure they are all basic types
    for (const key in param) {
      if (Object.prototype.hasOwnProperty.call(param, key)) {
        const type = typeof param[key];
        if (
          type !== "string" &&
          type !== "number" &&
          type !== "boolean" &&
          param[key] !== null &&
          type !== "undefined"
        ) {
          return "ComplexObject";
        }
      }
    }
    return "SingleRecord";
  }

  return "ComplexObject";
}

function getNGResultType(param: any) {
  if (isSimpleType(param)) return "SimpleValue";

  // Check if the parameter is an object but not null
  if (param !== null && typeof param === "object") {
    if (!isNil(param["Items"]) && isArray(param["Items"])) {
      return "MultipleRows";
    }

    return "SingleRecord";
  }

  throw new Error("Invalid result type");
}

const functions = {
  // Math
  min: Math.min,
  max: Math.max,
  abs: Math.abs,
  round: Math.round,
  floor: Math.floor,
  ceil: Math.ceil,
  // String
  toLowerCase: String.prototype.toLowerCase,
  toUpperCase: String.prototype.toUpperCase,
  startsWith: String.prototype.startsWith,
  endsWith: String.prototype.endsWith,
  trim: String.prototype.trim,
  split: String.prototype.split,
  match: String.prototype.match,
  matchAll: String.prototype.matchAll,
  isNullOrEmpty: isNullOrEmpty,
  slug: makeSlug,
  //DateTime
  now: () => {
    return new Date();
  },
  nowAsISO: (userTimezone) => {
    const now = new Date();
    const dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false };

    const formattedDate = new Intl.DateTimeFormat("en-CA", dateOptions as any).format(now).replace(/\//g, "-");
    const formattedTime = new Intl.DateTimeFormat("en-CA", timeOptions as any).format(now).replace(/:/g, ":");

    return `${formattedDate}T${formattedTime}`;
  },
  nowAsString: () => {
    return new Date().toString();
  },

  //Objects
  clone: cloneDeep,
  toKvp: (o) => {
    const r: any[] = [];

    for (const key in o) {
      // eslint-disable-next-line no-prototype-builtins
      if (o.hasOwnProperty(key)) {
        r.push({ Key: key, Value: o[key] });
      }
    }

    return r;
  },
  keys: (o) => {
    return Object.keys(o);
  },
  setKey: (a, i, v) => {
    a[i] = v;
    return a;
  },
  removeProperty: (o, key) => {
    delete o[key];
    return o;
  },
  getNGObjectType: getNGObjectType,
  getNGResultType: getNGResultType,
  reduceNGProperties: (o) => {
    const transformed = {};

    if (isNil(o)) return transformed;

    Object.keys(o).forEach((key) => {
      transformed[key] = null;
    });

    return transformed;
  },
  merge: (o1, o2) => {
    return { ...o1, ...o2 };
  },
  isEmpty: (o) => isNil(o) || isEmpty(o),
  isNil: (o) => isNil(o),
  // General
  parseFloat: parseFloat,
  parseInt: parseInt,
  parseBool: (v) => {
    return v && v.toString().toLowerCase() === "true" ? true : false;
  },
  map: Array.prototype.map,
  filter: Array.prototype.filter,
  find: Array.prototype.find,
  // some: Array.prototype.some,
  some: (a, v) => a.some((a1) => a1.Id === v),
  includes: Array.prototype.includes,
  join: Array.prototype.join,
  concat: Array.prototype.concat,
  slice: Array.prototype.slice,
  firstWord: (v) => {
    if (v) return v.split(" ")[0];
  },
  addToArray: (a, x) => {
    if (!a) a = [];
    return a.concat([x]);
  },
  _map: function (a, f) {
    console.log("~~running map: ", a, f);
    return map(a, f);
  },
  isArray: isArray,
  isMenu: isMenu,

  // RegExp
  test: RegExp.prototype.test,
  exec: RegExp.prototype.exec,
  guid: generateGuid,
  uid: generateUID,
  nfeature: generateFeature,
  // JSON
  jsonParse: JSON.parse,
  jsonStringify: JSON.stringify,

  // Custom
  isNative: isNative,
  findChild: findChild,
  findMeta: findMeta,
  generatePageWithClonedMeta: generatePageWithClonedMeta,
  cloneJsonWithIDs: cloneJsonWithIDs,
  deleteMeta: deleteMeta,
  fromCharCode: (hex) => {
    return String.fromCharCode(parseInt(hex, 16));
  },
  buildTreeItems,
  mapMetadataRec,
  //Formatting
  formatNumber: formatNumber,
  formatDate: formatDate,
  //helpers
  belowAbove: (value, threshold, belowStr, aboveStr, equalStr = aboveStr) => {
    if (value < threshold) {
      return belowStr;
    } else if (value > threshold) {
      return aboveStr;
    } else {
      return equalStr;
    }
  },
  getFormData: getFormData,
  // OpenAPI
  parseOpenAPIServices: parseOpenAPIServices,
  getOpenAPIFields: (spec, path) => {
    //const s = resolveRefs(spec, spec);
    const s2 = extractFieldList(spec as any, path);
    const s3 = convertOpenAPIFieldsToPropertiesEditor(s2);
    return s3;
  },
  getOpenAPIServiceFromPath: getServiceFromPath,
  getFieldsFromForm: (form, sendEmptyFields) => {
    const o = Object.entries(form || {})
      .filter(([key, field]) => {
        if (isNil(field)) return false; // this will never happen, as the fields are signals

        if (!sendEmptyFields) if (isNullOrEmpty((field as any).value) || isEmpty((field as any).value)) return false;

        return true;
      })
      .map(([key, field]) => {
        return {
          Name: key,
          Value: (field as any).value,
        };
      });

    return o;
  },
  getFieldsWithBindingsFromForm: (form, sendEmptyFields) => {
    form ||= {};
    const o = Object.entries(form)
      .filter(([key, field]: [string, any]) => {
        if (isNil(field)) return false; // this will never happen, as the fields are signals
        if (!sendEmptyFields)
          if (isNullOrEmpty(field.value) || (field.value !== true && isEmpty(field.value))) return false;
        if (key.startsWith("Bindings.")) return false;

        return true;
      })
      .map(([key, field]) => {
        return {
          Name: key,
          Value: (field as any).value,
        };
      });

    const bindings = Object.entries(form).filter(([key, field]: [string, any]) => {
      if (isNil(field)) return false; // this will never happen, as the fields are signals
      if (!sendEmptyFields)
        if (isNullOrEmpty(field.value) || (field.value !== true && isEmpty(field.value))) return false;
      if (!key.startsWith("Bindings.")) return false;

      return true;
    });

    bindings.forEach(([key, field]) => {
      const componentField = o.find((f) => f.Name === key.split(".")[1]);
      if (componentField) {
        componentField.Bindings = { Value: (field as any).value };
      }
    });

    return o;
  },
  getFieldsFromServiceResult: convertServiceResultToPropertiesEditor,
  getPropertiesEditorDataFromServiceResult: getPropertiesEditorDataFromServiceResult,
  fieldsToObject: (fields) => {
    const nonBindingsFields = fields?.reduce(function (field, x) {
      field[x.Name] = x.Value;
      return field ?? {};
    }, {});
    const bindingsFields = fields?.reduce(function (field, x) {
      if (x.Bindings) {
        field[`Bindings.${x.Name}`] = x.Bindings.Value;
      } else {
        return {};
      }

      field[x.Bindings] = x.Value;
      return field ?? {};
    }, {});

    return { ...bindingsFields, ...nonBindingsFields };
  },
};

export function isStateVar(name: string | null) {
  return name != null && ["State", "Form", "Parent", "Global", "Repeater"].includes(name);
}

const systemProps = ["NGForm", "NGDialog", "NGService", "NGRepeater", "NGComponent", "NGContextMenu"];

function evaluateAst(
  ast: any,
  scope: any,
  defaultValue: any,
  setter: boolean,
  returnSignal: boolean,
  opts: InterpreterOptionsInner
) {
  let topSignal: Signal | null = null;

  function evaluate(node: any, context: any, level: number, nextValue = defaultValue) {
    if (node.type === "MemberExpression") {
      const isSearchIndex =
        node.computed &&
        node.property.type === "BinaryExpression" &&
        node.property.operator === "==" &&
        node.property.left.type === "Identifier";
      const prop = node.property;
      const property =
        node.property.type == "Identifier"
          ? prop.name
          : node.property.type == "MemberExpression"
            ? evaluate(node.property, context, level + 1)
            : prop.value;
      const newValue = isSearchIndex || isIndex(property) ? [] : {};

      const object = evaluate(node.object, context, level + 1, newValue);
      if (object == null) return null;

      if (isStateVar(node.object.name)) {
        if (!systemProps.includes(property)) {
          object[property] ||= signal(setter ? nextValue : level == 0 ? defaultValue : null);
        }
        if (topSignal == null) {
          topSignal = object[property];
        }
      }

      // Support array property filtering using this syntax:
      // E.g. "State.Menu.Features[Id==Form.Id].Label"
      if (isSearchIndex) {
        const right = evaluate(node.property.right, context, level + 1);
        const left = node.property.left.name;
        let v = object.find((x) => x[left] === right);
        if (setter && isNil(v)) {
          v = { [left]: right };
          object.push(v);
        }
        return v;
      } else if (has(object, property)) {
        const v = object[property];
        // For setter set the final value
        if (setter) {
          if (isSignal(v)) {
            // TODO: refactor
            if (level === 0) {
              opts.setFn(v, "value", defaultValue);
            } else {
              v.value = isNil(v.value) ? newValue : v.value;
            }
          } else {
            if (level === 0) {
              // TODO: refactor
              // object[property] = defaultValue;
              opts.setFn(object, property, defaultValue);
              if (topSignal) {
                topSignal.value = clone(topSignal.value);
              }
            } else {
              object[property] = isNil(object[property]) ? newValue : object[property];
            }
          }
        }
        // For getter we just keep returning values
        return !returnSignal && isSignal(v) ? v.value : v;
      } else if (has(context.functions, property)) {
        return {
          type: "MethodCall",
          object,
          method: context.functions[property],
        };
      } else {
        // Property doesn't exist on the object
        if (setter) {
          if (level === 0) {
            // TODO: refactor
            // object[property] = defaultValue;
            opts.setFn(object, property, defaultValue);
            if (topSignal) {
              topSignal.value = clone(topSignal.value);
            }
          } else {
            object[property] = nextValue;
          }
          return nextValue;
        } else {
          return null;
        }
      }
    } else if (node.type === "Identifier") {
      const name = node.name;
      if (has(context.scope, name)) {
        const v = context.scope[name];
        return isSignal(v) ? v.value : v;
      } else if (has(context.functions, name)) {
        return context.functions[name];
      } else if (has(context.vars, name)) {
        return context.vars[name];
      } else {
        return null;
      }
    } else if (node.type === "Literal") {
      return node.value;
    } else if (node.type === "ConditionalExpression") {
      const testResult = evaluate(node.test, context, level + 1);
      if (testResult) {
        return evaluate(node.consequent, context, level + 1);
      } else {
        return evaluate(node.alternate, context, level + 1);
      }
    } else if (node.type === "BinaryExpression") {
      const left = evaluate(node.left, context, level + 1);
      const right = evaluate(node.right, context, level + 1);

      switch (node.operator) {
        // Multiplicative
        case "*":
          return left * right;
        case "/":
          if (right === 0) {
            throw new Error("Division by zero");
          }
          return left / right;
        case "%":
          return left % right;
        // Additive
        case "+":
          return left + right;
        case "-":
          return left - right;
        // Shift
        // case "<<":
        //   return left << right;
        // case ">>>":
        //   return left >>> right;
        // case ">>":
        //   return left >> right;
        // Relational
        case "<=":
          return left <= right;
        case ">=":
          return left >= right;
        case "<":
          return left < right;
        case ">":
          return left > right;
        // Equality
        case "===":
        case "==":
          return left === right;
        case "!==":
        case "!=":
          return left !== right;
        // Bitwise
        // case "&":
        //   return left & right;
        // case "|":
        //   return left | right;
        // case "^":
        //   return left ^ right;
      }
    } else if (node.type === "LogicalExpression") {
      const left = evaluate(node.left, context, level + 1);
      const right = evaluate(node.right, context, level + 1);

      switch (node.operator) {
        // Logical
        case "&&":
          return left && right;
        case "||":
          return left || right;
        case "??":
          return left ?? right;
      }
    } else if (node.type === "ObjectExpression") {
      const obj = {};
      for (const prop of node.properties) {
        const key = prop.key.type == "Identifier" ? prop.key.name : prop.key.value;
        const value = evaluate(prop.value, context, level + 1);
        obj[key] = value;
      }
      return obj;
    } else if (node.type === "ArrayExpression") {
      return node.elements.map((x: any) => evaluate(x, context, level + 1));
    } else if (node.type === "FunctionExpression") {
      return function (...args: any) {
        const vars = {};
        for (let i = 0; i < node.params.length; i++) {
          vars[node.params[i].name] = args[i];
        }
        const childContext = {
          functions: context.functions,
          scope: context.scope,
          vars,
        };
        return evaluate(node.body, childContext, level + 1);
      };
    } else if (node.type === "CallExpression") {
      const callee = evaluate(node.callee, context, level + 1);
      if (callee == null) return null;
      const args: any[] = node.arguments.map((x: any) => evaluate(x, context, level + 1));
      if (typeof callee === "function") {
        args.push(setter, nextValue, level, topSignal, opts);
        return callee(...args);
      } else if (callee.type == "MethodCall") {
        return callee.method.apply(callee.object, args);
      }
    } else if (node.type === "UnaryExpression") {
      const value = evaluate(node.argument, context, level + 1);
      switch (node.operator) {
        case "~":
          return ~value;
        case "+":
          return +value;
        case "-":
          return -value;
        case "!":
          return !value;
      }
    } else if (node.type === "IfStatement") {
      const testResult = evaluate(node.test, context, level + 1);
      if (testResult) {
        return evaluate(node.consequent, context, level + 1);
      } else {
        return evaluate(node.alternate, context, level + 1);
      }
    } else if (node.type === "BlockStatement") {
      return evaluate(node.body, context, level + 1);
    }
  }
  return evaluate(ast, { scope, functions }, 0);
}

export function traverseAst(node, callback) {
  function visit(node, parent = null) {
    // Call the visitor function for the current node
    callback(node, parent);

    // Based on the node type, visit its children or related nodes
    switch (node.type) {
      case "MemberExpression":
        visit(node.object, node);
        visit(node.property, node);
        break;

      case "Identifier":
      case "Literal":
        // These are leaf nodes, but the visitor function is called for them above
        break;

      case "BinaryExpression":
      case "LogicalExpression":
        visit(node.left, node);
        visit(node.right, node);
        break;

      case "ConditionalExpression":
        visit(node.test, node);
        visit(node.consequent, node);
        visit(node.alternate, node);
        break;

      case "CallExpression":
        visit(node.callee, node);
        node.arguments.forEach((arg) => visit(arg, node));
        break;

      case "UnaryExpression":
        visit(node.argument, node);
        break;

      case "ArrayExpression":
        node.elements.forEach((element) => visit(element, node));
        break;

      case "ObjectExpression":
        node.properties.forEach((prop) => {
          visit(prop.key, node);
          visit(prop.value, node);
        });
        break;

      case "FunctionExpression":
        node.params.forEach((param) => visit(param, node));
        visit(node.body, node);
        break;

      case "BlockStatement":
        node.body.forEach((statement) => visit(statement, node));
        break;

      case "IfStatement":
        visit(node.test, node);
        visit(node.consequent, node);
        if (node.alternate) {
          visit(node.alternate, node);
        }
        break;

      // Add any additional cases that might be relevant
      // ...

      default:
        // Log or handle unimplemented node types, if necessary
        console.warn(`Unhandled node type: ${node.type}`);
    }
  }

  visit(node, null); // Start traversal from the root node
}

export function collectFormProperties(expr) {
  const ast = getAst(expr);
  const props: string[] = [];
  traverseAst(ast, (node) => {
    if (node.type === "MemberExpression" && node.object.type === "Identifier" && node.object.name === "Form") {
      const prop = node.property.name || node.property.value;
      props.push(prop);
    }
  });
  return props;
}
// export function collectFormProperties(expr) {
//   const ast = getAst(expr);
//   const props: string[] = [];

//   // Assuming traverseAst is a generic AST traversal function that calls
//   // the provided callback for every node in the AST.
//   traverseAst(ast, (node) => {
//     if (node.type === "MemberExpression") {
//       const propertyPath = buildPropertyChain(node);
//       if (propertyPath.startsWith("Form.")) {
//         props.push(propertyPath.substring(5)); // Remove the 'Form.' part to store the property chain
//       }
//     }
//   });

//   function buildPropertyChain(node) {
//     let current = node;
//     let pathComponents: string[] = [];
//     while (current && current.type === "MemberExpression") {
//       if (current.property.type === "Identifier") {
//         pathComponents.unshift(current.property.name);
//       } else if (current.property.type === "Literal") {
//         pathComponents.unshift(current.property.value);
//       }
//       current = current.object;
//     }
//     if (current.type === "Identifier") {
//       pathComponents.unshift(current.name);
//     }
//     return pathComponents.join(".");
//   }

//   console.log(`~~Found property related to Form: `, props);
//   return props;
// }
// export function collectFormProperties(expr) {
//   const ast = getAst(expr);
//   const props = new Set();

//   function isTerminalMemberExpression(node) {
//     // A terminal MemberExpression has a MemberExpression as its parent,
//     // but only if this node's object is directly referenced by the parent.
//     // This function checks if the node is not used as an object by another MemberExpression,
//     // making it a terminal or leaf MemberExpression in the property chain.
//     return !(node.parent && node.parent.type === "MemberExpression" && node.parent.object === node);
//   }

//   function buildPropertyChain(node) {
//     let path: string[] = [];
//     let current = node;

//     while (current && current.type === "MemberExpression") {
//       // Collect property names, diving up the chain
//       const propName = current.property.type === "Identifier" ? current.property.name : current.property.value;
//       path.unshift(propName); // Add property name at the beginning of the path array
//       current = current.object; // Move up the chain
//     }

//     // Ensure the chain starts with 'Form'
//     if (current && current.type === "Identifier" && current.name === "Form") {
//       path.unshift("Form"); // Include 'Form' at the start of the path
//       return path.join(".");
//     }
//     return "";
//   }

//   traverseAst(ast, (node, parent) => {
//     // Annotate each node with its parent for context in checks
//     node.parent = parent;

//     if (node.type === "MemberExpression" && isTerminalMemberExpression(node)) {
//       const propertyPath = buildPropertyChain(node);
//       if (propertyPath.startsWith("Form.")) {
//         // Remove the 'Form.' part to store only the property chain
//         props.add(propertyPath.substring(5));
//       }
//     }
//   });

//   // Convert the Set to an array to return
//   console.log(`~~Found property related to Form: `, props);
//   return Array.from(props);
// }
